import { PriorityHighBoldIcon, PriorityMediumBoldIcon, PriorityLowBoldIcon } from '@/assets/icons';

export const TASK_TYPE = {
  DEFINE_PRODUCT: 'define_product',
  ALLOCATION_BILLING: 'allocation_billing',
  DECLARE_DELIVERY: 'declare_delivery',
  MAP_PRODUCTS: 'map_product',
  BALANCEALIGNMENT_RECONCILIATION: 'balanceAlignment_reconciliation',
  HANDLERECONCILIATION_RECONCILIATION: 'handleReconciliation_reconciliation',
  UNCERTAINBILLING_BILLING: 'uncertainBilling_billing',
  RECORD_DOCUMENT: 'record_document',
  UPLOAD_DOCUMENT: 'upload_document',
};

export const REVIEW_REQUIRED_OPTION = {
  ALL: 'all',
  REVIEW_REQUIRED: 'reviewRequired',
  REVIEW_NOT_REQUIRED: 'reviewNotRequired',
};

export const taskPriorityIconMapping = [PriorityLowBoldIcon, PriorityMediumBoldIcon, PriorityHighBoldIcon];
