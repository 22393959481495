<template>
  <div>
    <TableLoadingSkeleton v-if="loading" />
    <Table
      v-else
      custom-class="tasks-table"
      :data="tableData"
      :columns="columns"
      show-index
      rounded
      border
      @row-click="onRowClick"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + pageLimit * (currentPage - 1) }}
      </template>
      <template #[`cell-${TABLE_HEADERS.STATUS}`]="{ rowData: task }">
        <div class="d-flex">
          <TaskTag :display-mode="true" :task="task" />
          <TruncatedText class="w-75 px-2">{{ task?.data?.comment }}</TruncatedText>
        </div>
      </template>
      <template #[`cell-${TABLE_HEADERS.TASK}`]="{ rowData: task }">
        {{ $t(`tasks.tasksPage.tasksName.${getTaskName(task)}`) }}
      </template>
      <template #[`cell-${TABLE_HEADERS.CREATED_DAYS_AGO}`]="{ rowData: task }">
        {{
          $tc('tasks.tasksPage.task.createdDaysAgo', task.createdAtRelative, { days: task.createdAtRelative })
        }}</template
      >
      <template #[`cell-${TABLE_HEADERS.PRIORITY}`]="{ rowData: task }">
        <component :is="taskPriorityIconMapping[task.priority]"></component>
      </template>
      <template #[`cell-${TABLE_HEADERS.REVIEW_REQUIRED}`]="{ rowData: task }">
        <el-tooltip
          v-show="task.reviewRequired"
          :content="$t('tasks.tasksPage.tasksTable.managerReviewRequired')"
          placement="top"
        >
          <FlagFullIcon v-show="task.reviewRequired" size="14" fill-color="#E47E03" />
        </el-tooltip>
      </template>
    </Table>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';
import { Table, TableLoadingSkeleton, TruncatedText } from '@/modules/core';
import { TaskTag } from '@/modules/tasks/taskTag';
import { useBusinessByNames } from '@/modules/business/compositions/business';
import { DateTime } from 'luxon';
import { FlagFullIcon } from '@/assets/icons';
import { taskPriorityIconMapping, TASK_TYPE } from '../configuration';

const TABLE_HEADERS = {
  STATUS: 'status',
  TASK: 'task',
  BUSINESS_NAME: 'businessName',
  CREATED_DAYS_AGO: 'createdDaysAgo',
  REVIEW_REQUIRED: 'reviewRequired',
  PRIORITY: 'priority',
};

export default {
  name: 'TasksTable',
  components: {
    Table,
    TruncatedText,
    TaskTag,
    FlagFullIcon,
    TableLoadingSkeleton,
  },
  props: {
    tasks: { type: Array, required: true },
    loading: { type: Boolean, default: false },
    currentPage: { type: Number, required: true },
    pageLimit: { type: Number, required: true },
  },
  emits: ['on-row-click'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;

    const tasks = computed(() => props.tasks ?? []);

    const tasksBusinessesIds = computed(() => {
      const businessIds = tasks?.value?.map(({ businessId }) => businessId) ?? [];
      return [...new Set(businessIds)];
    });

    const { result: tasksBusinessesName } = useBusinessByNames({
      businessIds: tasksBusinessesIds,
    });

    const tableData = computed(() => {
      if (!tasks.value || !tasksBusinessesName.value?.length) return [];

      return (
        tasks.value?.map((task) => {
          const createdAt = task.createdAt;
          const daysAgo = Math.abs(Math.round(DateTime.fromMillis(createdAt).diffNow('days').days));

          return {
            ...task,
            businessName: tasksBusinessesName.value?.find((business) => business.id === task.businessId)?.name,
            createdAtRelative: daysAgo,
          };
        }) ?? []
      );
    });

    const getTaskName = (task) => {
      const { type, domain } = task;
      const taskName = `${type}_${domain}`.toUpperCase();

      return TASK_TYPE[taskName] ?? '';
    };

    const onRowClick = (rowIndex) => emit('on-row-click', tasks.value[rowIndex].id);

    const columns = [
      {
        header: root.$i18n.t(`tasks.tasksPage.tasksTable.headers.${TABLE_HEADERS.STATUS}`),
        key: TABLE_HEADERS.STATUS,
        width: '60%',
      },
      {
        header: root.$i18n.t(`tasks.tasksPage.tasksTable.headers.${TABLE_HEADERS.TASK}`),
        key: TABLE_HEADERS.TASK,
        width: '12%',
      },
      {
        header: root.$i18n.t(`tasks.tasksPage.tasksTable.headers.${TABLE_HEADERS.BUSINESS_NAME}`),
        key: TABLE_HEADERS.BUSINESS_NAME,
        width: '14%',
      },
      {
        header: root.$i18n.t(`tasks.tasksPage.tasksTable.headers.${TABLE_HEADERS.CREATED_DAYS_AGO}`),
        key: TABLE_HEADERS.CREATED_DAYS_AGO,
        width: '8%',
      },
      {
        header: root.$i18n.t(`tasks.tasksPage.tasksTable.headers.${TABLE_HEADERS.PRIORITY}`),
        key: TABLE_HEADERS.PRIORITY,
        width: '4%',
      },
      {
        header: '',
        key: TABLE_HEADERS.REVIEW_REQUIRED,
        width: '4%',
      },
    ];

    return {
      TABLE_HEADERS,
      tableData,
      columns,
      getTaskName,
      TASK_TYPE,
      onRowClick,
      taskPriorityIconMapping,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep {
  .el-select-dropdown.el-popper {
    width: 250px;
  }
  .table-responsive {
    overflow: unset;
  }
}

.truncated-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  word-break: break-word;
}

.expandable:hover {
  -webkit-line-clamp: unset;
  line-clamp: unset;
  display: inline-block;
  padding: 0.5rem;
  background: #fff;
  position: absolute;
  border-radius: 6px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 31rem;
  margin-top: -1rem;
}
</style>
