<template>
  <div>
    <TableLoadingSkeleton v-if="loading" />
    <Table
      v-else
      custom-class="tasks-table"
      :data="tableData"
      :columns="columns"
      show-index
      rounded
      border
      @row-click="onRowClick"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + pageLimit * (currentPage - 1) }}
      </template>

      <template #[`cell-${TABLE_HEADERS.COMPLETED_AT}`]="{ rowData: task }">
        <div class="d-flex">
          {{ formatDate(task.completedAt, 'd.M.yy, HH:mm') }}
        </div>
      </template>

      <template #[`cell-${TABLE_HEADERS.COMPLETED_BY}`]="{ rowData: task }">
        <div class="d-flex">
          {{ task.completedBy }}
        </div>
      </template>

      <template #[`cell-${TABLE_HEADERS.TASK_TYPE}`]="{ rowData: task }">
        <TruncatedText class="w-100"> {{ $t(`tasks.tasksPage.tasksName.${getTaskName(task)}`) }}</TruncatedText>
      </template>

      <template #[`cell-${TABLE_HEADERS.COMMENTS}`]="{ rowData: task }">
        <div class="d-flex">
          <TruncatedText class="w-100">{{ task?.data?.comment }}</TruncatedText>
        </div>
      </template>

      <template #[`cell-${TABLE_HEADERS.BUSINESS_NAME}`]="{ rowData: task }">
        <div class="d-flex">
          <TruncatedText class="w-100">{{ task?.businessName }}</TruncatedText>
        </div>
      </template>

      <template #[`cell-${TABLE_HEADERS.CREATED_AT}`]="{ rowData: task }">
        <div class="d-flex">
          {{ formatDate(task.createdAt, 'd.M.yy') }}
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';
import { Table, TableLoadingSkeleton, TruncatedText } from '@/modules/core';
import { useBusinessByNames } from '@/modules/business/compositions/business';
import { TASK_TYPE } from '../configuration';
import { DateTime } from 'luxon';

const TABLE_HEADERS = {
  COMPLETED_AT: 'completedAt',
  COMPLETED_BY: 'completedBy',
  TASK_TYPE: 'type',
  COMMENTS: 'comments',
  BUSINESS_NAME: 'businessName',
  CREATED_AT: 'createdAt',
};

export default {
  name: 'TasksTable',
  components: {
    Table,
    TruncatedText,
    TableLoadingSkeleton,
  },
  props: {
    tasks: { type: Array, required: true },
    loading: { type: Boolean, default: false },
    currentPage: { type: Number, required: true },
    pageLimit: { type: Number, required: true },
  },
  emits: ['on-row-click'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;

    const tasks = computed(() => props.tasks ?? []);

    const tasksBusinessesIds = computed(() => {
      const businessIds = tasks?.value?.map(({ businessId }) => businessId) ?? [];
      return [...new Set(businessIds)];
    });

    const { result: tasksBusinessesName } = useBusinessByNames({
      businessIds: tasksBusinessesIds,
    });

    const tableData = computed(() => {
      if (!tasks.value || !tasksBusinessesName.value?.length) return [];

      return (
        tasks.value?.map((task) => {
          const completedBy = getUserName(task?.completedBy);
          return {
            ...task,
            completedBy,
            businessName: tasksBusinessesName.value?.find((business) => business.id === task.businessId)?.name,
          };
        }) ?? []
      );
    });

    const getUserName = (user) => {
      if (!user) return root.$i18n.t(`tasks.tasksPage.closedTasksTable.fields.systemUser`);
      const { firstName, lastName } = user;
      return `${firstName} ${lastName}`;
    };

    const getTaskName = (task) => {
      const { type, domain } = task;
      const taskName = `${type}_${domain}`.toUpperCase();

      return TASK_TYPE[taskName] ?? '';
    };

    const onRowClick = (rowIndex) => emit('on-row-click', tasks.value[rowIndex].id);

    const columns = [
      {
        header: root.$i18n.t(`tasks.tasksPage.closedTasksTable.headers.${TABLE_HEADERS.COMPLETED_AT}`),
        key: TABLE_HEADERS.COMPLETED_AT,
        width: '15%',
      },
      {
        header: root.$i18n.t(`tasks.tasksPage.closedTasksTable.headers.${TABLE_HEADERS.COMPLETED_BY}`),
        key: TABLE_HEADERS.COMPLETED_BY,
        width: '15%',
      },
      {
        header: root.$i18n.t(`tasks.tasksPage.closedTasksTable.headers.${TABLE_HEADERS.TASK_TYPE}`),
        key: TABLE_HEADERS.TASK_TYPE,
        width: '15%',
      },
      {
        header: root.$i18n.t(`tasks.tasksPage.closedTasksTable.headers.${TABLE_HEADERS.COMMENTS}`),
        key: TABLE_HEADERS.COMMENTS,
        width: '25%',
      },
      {
        header: root.$i18n.t(`tasks.tasksPage.closedTasksTable.headers.${TABLE_HEADERS.BUSINESS_NAME}`),
        key: TABLE_HEADERS.BUSINESS_NAME,
        width: '25%',
      },
      {
        header: root.$i18n.t(`tasks.tasksPage.closedTasksTable.headers.${TABLE_HEADERS.CREATED_AT}`),
        key: TABLE_HEADERS.CREATED_AT,
        width: '140px',
      },
    ];

    const formatDate = (timestamp, format) => {
      return DateTime.fromMillis(timestamp).toFormat(format);
    };

    return {
      TABLE_HEADERS,
      tableData,
      columns,
      getTaskName,
      TASK_TYPE,
      onRowClick,
      formatDate,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep {
  .el-select-dropdown.el-popper {
    width: 250px;
  }
  .table-responsive {
    overflow: unset;
  }
}

.truncated-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  word-break: break-word;
}

.expandable:hover {
  -webkit-line-clamp: unset;
  line-clamp: unset;
  display: inline-block;
  padding: 0.5rem;
  background: #fff;
  position: absolute;
  border-radius: 6px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 31rem;
  margin-top: -1rem;
}
</style>
